import React, { useState, useEffect } from 'react';
import firebase from '../../../firebase';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import 'firebase/storage';
import backgroundImage from '../posts/img/coloseum.jpg';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const CreatePost = ({ user }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [tags, setTags] = useState([]);
  const [selectedTag1, setSelectedTag1] = useState('');
  const [selectedTag2, setSelectedTag2] = useState('');
  const [tag1Changed, setTag1Changed] = useState(false); // Track if tag1 has been changed
  const [tag2Changed, setTag2Changed] = useState(false); // Track if tag2 has been changed
  const navigate = useNavigate();
  const [imageURL, setImageURL] = useState('');
  const [image, setImage] = useState('');
  const [error, setError] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({ title: false, content: false, image: false });
  const [showSecondDropdown, setShowSecondDropdown] = useState(false);

  useEffect(() => {
    // Fetch tags from tags.json
    fetch('/tags.json')
      .then(response => response.json())
      .then(data => setTags(data))
      .catch(error => console.error('Error fetching tags:', error));
  }, []);

  useEffect(() => {
    adjustWidth(document.querySelector('.dynamic-width-select-1'));
    adjustWidth(document.querySelector('.dynamic-width-select-2'));
  }, [selectedTag1, selectedTag2, showSecondDropdown]);

  const adjustWidth = (selectElement) => {
    if (!selectElement) return;
    const tempOption = document.createElement('option');
    tempOption.textContent = selectElement.options[selectElement.selectedIndex]?.text || '';
    document.body.appendChild(tempOption);
    const tempSelect = document.createElement('select');
    tempSelect.style.visibility = 'hidden';
    tempSelect.style.position = 'absolute';
    tempSelect.appendChild(tempOption);
    document.body.appendChild(tempSelect);
    const width = tempSelect.clientWidth;
    document.body.removeChild(tempSelect);
    selectElement.style.width = `${width + 5}px`; // Add some padding
  };

  const handleCreatePost = async (e) => {
    e.preventDefault();
    setFieldErrors({ title: false, content: false, image: false });
    setErrorMessage('');

    if (!title || !content || !image || !selectedTag1 || !tag1Changed || (showSecondDropdown && !selectedTag2 && !tag2Changed)) {
      setFieldErrors({ title: !title, content: !content, image: !image });
      setErrorMessage('Please fill in all fields and select valid tags.');
      return;
    }

    let imageUrl = '';
    if (image) {
      const storage = getStorage();
      const metadata = { contentType: 'image/jpeg' };
      const imageName = `${user.uid}${image.name}${Date.now()}`;
      const storageRef = ref(storage, `images/${imageName}`);
      imageUrl = `images/${imageName}`;
      const uploadTask = uploadBytesResumable(storageRef, image, metadata);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          switch (error.code) {
            case 'storage/unauthorized':
            case 'storage/canceled':
            case 'storage/unknown':
              break;
          }
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setImageURL(downloadURL);
          });
        }
      );
    }

    const newDate = new Date();
    const timeStamp = `${newDate.getFullYear()}${String(newDate.getMonth() + 1).padStart(2, '0')}${String(newDate.getDate()).padStart(2, '0')}`;
    const formattedContent = content.replace(/\n/g, '<br/>');

    firebase.firestore().collection('Posts').add({
      title,
      content: formattedContent,
      imageURL: imageUrl,
      author: user.uid,
      timeStamp,
      tags: [selectedTag1, selectedTag2],
    })
    .then(() => {
      setTitle('');
      setContent('');
      navigate('/');
      window.location.reload();
    })
    .catch((error) => {
      setError(error.message);
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
  if (file) {
    const validTypes = ['image/jpeg', 'image/png'];
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    if (!validTypes.includes(file.type)) {
      setErrorMessage('Only JPEG and PNG formats are allowed.');
      setImage(null);
      return;
    }

    if (file.size > maxSize) {
      setErrorMessage('File size must be less than 2MB.');
      setImage(null);
      return;
    }

    setImage(file);
    setErrorMessage(''); // Clear any previous error messages
  }
  };

  const handleImageDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files[0]) {
      setImage(e.dataTransfer.files[0]);
    }
  };

  const handleImageDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <img src={backgroundImage} alt="Background" className="background-image-add" />
      <div className="create-post-container">
        <h2>Pridaj článok</h2>
        <input
          type="text"
          style={{ borderColor: fieldErrors.title ? 'red' : '' }}
          placeholder="Názov, dĺžka max. 90 písmen"
          maxLength="90"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <textarea
          style={{ borderColor: fieldErrors.content ? 'red' : '' }}
          placeholder="Tu napíš telo článku. Veľkosť okna zmeníš potiahnutím pravého dolného rohu"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        <input
          type="file"
          style={{ borderColor: fieldErrors.image ? 'red' : '' }}
          placeholder="prehľadávaj"
          onChange={handleImageChange}
          accept="image/*"
        />
         
        <div className="tag-container">
          <select
            className="form-control dynamic-width-select-1 centered-select"
            value={selectedTag1}
            onChange={(e) => {
              setSelectedTag1(e.target.value);
              setTag1Changed(true); // Mark tag1 as changed
            }}
            required
          >
            <option value="">Vyber Tag</option>
            {tags.map((tag, index) => (
              <option key={index} value={tag}>{tag}</option>
            ))}
          </select>
          {!showSecondDropdown && (
            <button className="toggle-button" onClick={() => setShowSecondDropdown(true)}>+</button>
          )}
          {showSecondDropdown && (
            <>
              <select
                className="form-control dynamic-width-select-2 centered-select"
                value={selectedTag2}
                onChange={(e) => {
                  setSelectedTag2(e.target.value);
                  setTag2Changed(true); // Mark tag2 as changed
                }}
              >
                <option value="">Pridavny tag</option>
                {tags.map((tag, index) => (
                  <option key={index} value={tag}>{tag}</option>
                ))}
              </select>
              <button className="toggle-button" onClick={() => setShowSecondDropdown(false)}>-</button>
            </>
          )}
        </div>

        <button className="submit-button" onClick={handleCreatePost}>Hoď ho tam</button>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default CreatePost;