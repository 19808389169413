import React, { useState } from 'react';
import { auth } from '../../firebase';
import './style.css';
import { Link } from 'react-router-dom';

// Required for side-effects
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { collection, addDoc, doc, setDoc } from "firebase/firestore"; 
import { db } from '../../firebase';
import 'firebase/compat/firestore';
import { useNavigate } from "react-router-dom";
import backgroundImage from './images/bac1111.jpg'
import loginIm from './images/bona.jpg'

const SignInSignUpPage = () => {
  const history = useNavigate()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isSignUp, setIsSignUp] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [modalEmailError, setModalEmailError] = useState('');


  function ResetPasswordModal({ isOpen, onClose, onSubmit }) {
    const [email, setEmail] = useState('');
  
    if (!isOpen) return null;
  
    return (
      <div className="modal">
        <div className="modal-content">
          <span className="close" onClick={onClose}>&times;</span>
          <h2>Reset Password</h2>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button onClick={() => onSubmit(email)}>Submit</button>
        </div>
      </div>
    );
  }



  const handleForgotPassword = async (email) => {
    if (!email) {
      setModalEmailError('Please enter your email address.'); // Set error if email is empty
      return;
    }
    try {
      await auth.sendPasswordResetEmail(email);
      setModalEmailError(''); // Clear any existing errors
      setShowResetPasswordModal(false); // Close the modal
    } catch (error) {
      setModalEmailError(error.message); // Set error from Firebase or other auth service
    }
  };

  const handleSignUp = (e) => {
    if (password !== confirmPassword) {
      // Step 3: Set an error message if passwords do not match
      setError('Passwords do not match');
      return;
    }
    e.preventDefault();
    auth
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        return firebase.firestore().collection('users').doc(user.uid).set({
          displayName: displayName,
          email: email,
        })
        .then((docRef) => {
          // docRef.id contains the ID of the newly added document
          return user;
        });
      
      })
      .then((user) => {
        history("/");
        window.location.reload();
        return user;
      })

     
      .catch((error) => {
        if (error.code === "auth/invalid-email") {
          setError("Zlý e-mail alebo heslo");
        } 
        else {
          setError(error.message);
        }
      });
  };


  const handleSignIn = () => {

    auth
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Handle successful sign-in

        history("/");
        window.location.reload();
        console.log(userCredential.user);
      })
      .catch((error) => {
        if (error.code === "auth/invalid-email") {
            setError("Zlý e-mail alebo heslo");
          } 
          else {
            setError(error.message);
          }
      });
  };

  const toggleSignUp = () => {
    setIsSignUp(!isSignUp);
    setError(null);
    setEmail('');
    setPassword('');
  };
  auth
  .onAuthStateChanged(function(user) {
    if (user) {
      console.log("logged")
    } else {
        console.log("not logged")
    }
  });
  
  const shouldRenderImage = window.innerWidth > 900;
  const smallScreen = window.innerWidth < 900;

  return (
    <div className="signIn-signUp-container">
      <img src={backgroundImage} alt="Background" className="background-image" />
    
      {isSignUp ?  
    <div className="container-neh">
{
          shouldRenderImage && 
      (<div className="image-container-neh">
        <img src={loginIm} alt="Image" />
      </div> )
}
      <div className="form-container">
      <h2>Zaregistruj sa</h2>
        
        <div className='neh-from'>
        <input
          type="text"
          placeholder="Display Name"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
        />
        
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />  
              <div className="error-container">
                {error && <div className="error-message">{error}</div>}
              </div>
      </div>
      
      <Link className="button-link"><button onClick={handleSignUp}>Zaregistruj sa</button></Link>
            <div className='tesxt-dole'>  
                <p style={{color:'white'}}>Už máš účet?</p>
                <p className='point'>👉</p>
                <p className='regpri' onClick={toggleSignUp}>Prihlás sa</p>
            </div>
      </div>
    </div>
   
      :
      
      <div className="container-neh">
{
          shouldRenderImage && 
      (<div className="image-container-neh">
        <img src={loginIm} alt="Image" />
      </div> )
}
          <div className="form-container"> 
            <h2>Prihlás sa</h2>
            <div className='neh-from'>
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                /> 
              <div className="error-container">
                {error && <div className="error-message">{error}</div>}
              </div>
            </div> 
            <Link  className="button-link"><button onClick={handleSignIn}>Prihlás sa</button></Link>
            <div className='tesxt-dole'>  
                <p style={{color:'white'}}>Potrebuješ účet?</p>
                <p className='point'>👉</p>
                <p  className='regpri' onClick={toggleSignUp} >Zaregistruj sa</p>
                
            </div>
            <div className="forgot-password-link">
              <p onClick={() => setShowResetPasswordModal(true)}>Forgot Password?</p>
            </div>
            {showResetPasswordModal && (
            <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={() => setShowResetPasswordModal(false)}>&times;</span>
                <h2>Reset Password</h2>
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="modal-email-input" // Add this class
                />
                {modalEmailError && <p className="modal-error-message">{modalEmailError}</p>} {/* Display error message */}
                <button onClick={() => handleForgotPassword(email)}>Submit</button>
              </div>
            </div>
          )}
          
          </div> 
    </div>
    }
    </div>  
  );

};

export default SignInSignUpPage;
export { auth }