import React from 'react';
import './style.css';
import backgroundImage from './imageFront/walp.jpg'
import GoToArticleButton from './GoToArticleButton';
import pic from './imageFront/clanky1.png'
import pic2 from './imageFront/clanky2.png'

const FrontPage = () => {



  const shouldRenderImage = window.innerWidth > 850;

  return (

    <div className="container-main">

      <style>
        @import url('https://fonts.googleapis.com/css2?family=Ysabeau+Infant:ital,wght@0,300;1,100;1,200&display=swap');
      </style> 

      <img src={backgroundImage} alt="Background" className="background-image" />
      
      <div className="container-ban">

        <div className="images-container">

          <img className="image" src={pic} alt="Image 1" />

            <div className="middle-content">

                <div className="header">Librarium</div>
                <div className="subheader">by Centurio</div>
                <div className="text"><br/>Clanky pisane komunitou, <br /> pre komunitu</div>

            </div>
            <div class="arrow">
            <GoToArticleButton />
            </div>
           
          {
          shouldRenderImage && 

          (<img className="image" src={pic2} alt="Image 2" />)
          
          }

        </div>

      </div>

    </div>
  );
};

export default FrontPage;