import React, { useState, useEffect } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import './style.css';

const Post = ({ title, content, bgColor, image }) => {
  const cardStyle = {
    backgroundColor: bgColor,
    // borderRadius: '10px',
    // padding: '20px',
    // overflow: 'hidden',
    // margin: '10px',
    // width: '600px',
    // height: '300px',
  };

  const [finalImage, setFinalImage] = useState('');
  const storage = getStorage();
  const storageRef = ref(storage, image);

  useEffect(() => {
    getDownloadURL(storageRef)
      .then((url) => {
        setFinalImage(url);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [image]);

  return (
    <div className="post" style={cardStyle}>
      <h2 className='h2post'>{title}</h2>
      <div className="flex-containeros">
        <div className="post-image-container">
          <img src={finalImage}  className='post-imagee' />
        </div>
        <div style={{ flex: '2' }}>
 
          <div className="post-text" dangerouslySetInnerHTML={{ __html: content }} />

        </div>
      </div>
      <hr style={{  border: '0.3px solid #261903', opacity: '0.3'}} />
      <p style={{  margin: '0'}}>Zisti viac</p>
    </div>
  );
};

export default Post;
