import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Instagram } from 'react-bootstrap-icons';
import styles from './NavBar.module.css';
import { auth, checkUserLoggedIn } from '../../firebase';
import logo from '../Pages/posts/img/centurio_logo.png'; // Import the logo

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 896);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 896);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    checkUserLoggedIn()
      .then((user) => setIsLoggedIn(!!user))
      .catch((error) => console.error('Error checking user login status:', error));
  }, []);

  const toggleNav = () => setIsOpen(!isOpen);

  const handleLogout = () => {
    auth.signOut();
    setIsLoggedIn(false);
  };

  return (
    <div className={styles.NavBar}>
      
      <ul>
        <li className={styles.NavBarItems}>
            <div className={`${styles.HamburgerMenu} ${isOpen ? styles.HamburgerMenuOpen : ''}`} onClick={toggleNav}>
              <div></div>
              <div></div>
              <div></div>
          </div>
        
        </li>
      
         
        {!isLoggedIn && (
          <li className={styles.NavBarButton}>
            <Link to="/signinsignup"><a>Prihlás sa</a></Link>
          </li>
        )}
        {isLoggedIn && (
          <li className={styles.NavBarButton}>
            {/* <Link to="/clanky"><a>Profil</a></Link>
            <a> | </a> */}
            <a onClick={handleLogout}>Odhlás sa</a>
          </li>
        )}
        <li className={`${styles.NavBarIcons} ${styles['hide-on-mobile']}`}>
          <a href="https://www.facebook.com/profile.php?id=100083247636468"><Facebook color="white" size={20} /></a>
          <a href="https://www.instagram.com/centurio.official/"><Instagram color="white" size={20} /></a>
        </li>
        
        <div className={` ${isOpen ? '' : styles.NavBarItemsHidden}`}>
        <li className={styles.NavBarMenu}>  
          <Link to="/posts"><a>Články</a></Link>
          
          {isLoggedIn && <Link to="/create-post"><a>Pridaj článok</a></Link>}
          <div className={`${isOpen ? styles['show-on-mobile'] : styles['hide']}`}>
            <div className={styles.NavBarIcons}>
              <a href="https://www.facebook.com/profile.php?id=100083247636468"><Facebook color="white" size={20} /></a>
              <a href="https://www.instagram.com/centurio.official/"><Instagram color="white" size={20} /></a>
            </div>
          </div>
        </li>
        
        
        </div>
        <div className={` ${isOpen ? styles.NavBarItemsHidden : '' }`}>
   
            <li className={styles.NavBarLogo}>
            <a href="/">
              <img src={logo} alt="Centurio Logo" />
            </a>
          </li>
      </div>
      </ul>
    </div>
  );
};

export default NavBar;