import React from 'react';
import './GoToArticleButton.css'; // Create a CSS file for styling
import arrowImage from './imageFront/scldown.png'; // Import your arrow PNG image
import { Link } from 'react-router-dom';


const GoToArticleButton = () => {
    return (
      <div className="button-container"><Link to='/posts'>
        <button className="arrow-button">
          
          <p>Go to article</p>
          <div className="arrow-down">
            <img src={arrowImage} alt="Arrow" className="arrow-image" />
          </div>
        </button></Link>
      </div>
    );
  };

export default GoToArticleButton;
