/*---------------------------------firebase connect----------------------------------------- */
// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";




// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAz0DMftM3xP9vn5196clAmX_lKbyY9lHg",
  authDomain: "centurio-7a916.firebaseapp.com",
  projectId: "centurio-7a916",
  storageBucket: "centurio-7a916.appspot.com",
  messagingSenderId: "787354938115",
  appId: "1:787354938115:web:278421ec95c2c560551736",
  measurementId: "G-V7JT44HB23"
};
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const checkUserLoggedIn = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe(); // Unsubscribe from the onAuthStateChanged listener
      resolve(user); // Resolve the promise with the user object (or null if not signed in)
    }, reject); // Reject the promise if there is an error
  });
};

const auth = firebase.auth();
// const currentUser = firebase.currentUser();
// export {currentUser}
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// const storage = firebase.storage()
// export {db, storage}
export const storage = getStorage(app);

export { auth };
export default firebase;
export {db};


/*---------------------------------firebase connect----------------------------------------- */
