// auth.js

import { useState, useEffect } from 'react';
import { auth } from './firebase'; // Update the path accordingly

export const useAuth = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
    
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return user;
};



export const signInWithEmailAndPassword = (email, password) => {
  return auth.signInWithEmailAndPassword(email, password);
};

export const signUpWithEmailAndPassword = (email, password) => {
  return auth.createUserWithEmailAndPassword(email, password);
};

export const sendPasswordResetEmail = (email) => {
  return auth.sendPasswordResetEmail(email);
};
