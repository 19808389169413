
import { Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar/NavBar';
import FrontPage from './components/Pages/Home/FrontPage';
import SignInSignUpPage from './components/SignInSignUp/SignInSignUpPage';
import NewPostForm from './components/Pages/add_post/post';
import { auth } from './components/SignInSignUp/SignInSignUpPage';
import { currentUser } from './firebase';
import { useAuth } from './auth';
import CreatePost from './components/Pages/add_post/post';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import RendetPosts from './components/Pages/posts/renderpost';


function App() {
  const user = useAuth();
  const navigate = useNavigate();
  return (
    <div className="App">
        <NavBar />  
        <Routes>
          <Route path='/' element={<FrontPage />}></Route>
          <Route path='/signinsignup' element={<SignInSignUpPage />} ></Route>
          <Route path="/create-post" element={<CreatePost user={user} />} />
          <Route path="/posts" element={<RendetPosts />} />
        </Routes>
        
    </div>
  );
}

export default App;
